
export const newsData = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42l4ckq.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nlho6.webp",
    name: 'VARUN SATIA',
    position:' Founder & CEO, Kraftshala',
    company: 'Ex-  FMS , BITS',
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42l6dzw.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nn29e.webp",
    name: 'Eshu Sharma',
    position:'Co-founder, Kraftshala',
    company: 'Ex- FMS, PEC',
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42l9z1z.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42npn79.webp",
    name: 'Nishtha Jain',
    position:' Marketing, Kraftshala.',
    company: 'Ex-IIM Luckow, NIT',
  },{
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42lbjvf.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nqys7.webp",
    name: 'Sarfaraz Khimani',
    position:'Founder, Performics',
    company: 'Ex - IIM Ahmedabad',
  },{
    image:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42le210.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nwxnw.webp",
    name: 'Piyush Dhanuka',
    position:'Strategic Partnerships',
    company: 'Ex - IIM Calcutta',
  },{
    image:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42lgavl.webp"
    ,
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nn29e.webp",
    name: 'Tejas Chaudhari',
    position:'Performance Marketing Lead - Ecommerce',
    company: 'Ex - IIM Lucknow',
    mtop: 'half'
  },{
    image:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42lhyni.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42o2829.webp",
    name: 'Arjun Sood',
    position:'Client partner',
    company: 'Ex - IIM Lucknow',
  },{
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42llyl8.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42o8e6y.webp",
    name: 'Ayushi Mona',
    position:'Director Marketing',
    company: 'Ex - MICA',
  },{
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42lnr8x.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42obd3x.webp",
    name: 'Mani Makkar',
    position:'Product Marketer',
    company: 'Ex - MICA',
  }
    
];