import React from "react";
import "./styles.scss";

const index = () => {
  return (
    <div className="lp-placements">
      <div className="lp-p-sub-head">Digital Marketing Placements</div>
      <div className="l-p-head">
        Why is Marketing Launchpad considered the <br className="mobile-none" />
        Best <br className="pc-none" />
        Digital Marketing Course <br className="pc-none" />
        with Placements?
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28kqanp.png"
        className="lp-p-underline"
        alt=""
      />

      <div className="lp-p-flex">
        <div className="lp-p-card">
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42j0v6r.png"
            alt=""
            className="lp-pin1"
          />
          <div className="lp-p-card-head mb-1">
            100% of Placements of <br className="pc-none" />
            ₹4.5L or more
          </div>
          <div className="lp-p-card-det">
            <div className="lp-p-card-det-content">
              <div className="lp-det-img-flex">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylc9fe.png"
                  className="card-det-icon"
                  alt=""
                />
                <div className="card-det-head">9.2 LPA</div>
              </div>
              <div className="card-det-desc">Highest Package</div>
            </div>
            <div className="lp-p-card-det-content">
              <div className="lp-det-img-flex">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yktf41.png"
                  className="avg-card-det-icon"
                  alt=""
                />
                <div className="card-det-head">4.9 LPA</div>
              </div>
              <div className="card-det-desc">Average Package</div>
            </div>
          </div>
          <div className="lp-p-card-det">
            <div className="lp-p-card-det-content">
              <div className="lp-det-img-flex">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42k611e.png"
                  className="card-det-icon"
                  alt=""
                />
                <div className="card-det-head">94%</div>
              </div>
              <div className="card-det-desc">Placement Rate</div>
            </div>
            <div className="lp-p-card-det-content">
              <div className="lp-det-img-flex">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42kar5l.png"
                  className="card-det-icon icon-wide"
                  alt=""
                />
                <div className="card-det-head">500+</div>
              </div>
              <div className="card-det-desc">Hiring Partners</div>
            </div>
          </div>
          <div className="lp-p-disc">Placement numbers as per Feb 2025</div>
        </div>
        <div className="lp-p-card lp-p-card-2">
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42j3suz.png"
            alt=""
            className="lp-pin2"
          />
          <div className="lp-p-card-head">
            Our Alumni work with <br />
            agencies & brands like:
          </div>
          <div className="lp-p-card-img">
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jdjzi.webp"
              className="lp-det-img"
              alt=""
            />
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jfvko.webp"
              className="lp-det-img"
              alt=""
            />
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jkv7t.webp"
              className="lp-det-img"
              alt=""
            />
          </div>
        </div>
        <div className="lp-p-card lp-p-card-2">
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42j61xm.png"
            alt=""
            className="lp-pin3"
          />
          <div className="lp-p-card-head">
            100% publicly published <br />
            Placement Reports since 2021
          </div>
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jpja2.webp"
            className="lp-det-img-2"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default index;
