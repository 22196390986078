

export const newsData = [
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Prachi's+Kraftshala+Story+++Marketing+Launchpad+Program.mp4",
    color: '#02BFF5',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42oe21o.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42n183n.webp",
    name: 'Prachi Kothiyal'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/My+Journey+From+Civil+Engineering+to+Digital+Marketing+++Kraftshala+Reviews+++Marketing+Launchpad.mp4",
    color: '#F28746',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42offde.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42n6cm5.webp",
    name: 'Somes Biswal',
    width: '140px', Mobwidth: '90px',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Paulson's+Journey+From+Engineering+to+Marketing+-+Kraftshala+Review.mp4",
    color: '#F1AE13',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ogpxv.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42n8sq1.webp",
    name: 'Paulson Joseph'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Perks+of+Being+a+Kraftshala+Alumni+at+Mediamint+++Kraftshala+Reviews+++Marketing+Launchpad+Reviews.mp4",
    color: '#64D8A5',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42oi0u0.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nanzo.webp",
    name: 'Shivam',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Charu's+Kraftshala+Story+++Marketing+Launchpad+Program.mp4",
    color: '#427EB2',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ojfe9.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ncam6.webp",
    name: 'Charu Kulsreshtha',
    width: '140px', Mobwidth: '90px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+to+Switch+From+Engineering+to+Marketing+++Kraftshala+Reviews+++Marketing+Launchpad+Reviews.mp4",
    color: '#02BFF5',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42okyy5.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nem08.webp",
    name: 'Prathamesh',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+Does+Kraftshala's+Training+Process+Look+Kraftshala+Alumni+Reviews+++Marketing+Launchpad+Review.mp4",
    color: '#F28746',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42omd41.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42n6cm5.webp",
    name: 'Suyash',
    width: '140px', Mobwidth: '90px',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+Does+Digital+Marketing+Career+After+Kraftshala+Look+Like+Kraftshala+Placements.mp4",
    color: '#F1AE13',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42onw2c.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nem08.webp",
    name: 'Pratyush Sarkar',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-From+Engineer+to+Marketer+-+My+Career+Transition+Journey+With+Kraftshala+_+Kraftshala+Experience.mp4",
    color: '#64D8A5',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42opzts.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42n183n.webp",
    name: 'Chethan Malpe'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-Why+Does+Sahil+Think+Kraftshala+is+Incomparable_+Kraftshala+Reviews+_+Marketing+Launchpad+Reviews.mp4",
    color: '#427EB2',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42or2e2.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nem08.webp",
    name: 'Sahil Mirza',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/From+Football+Coach+to+a+Digital+Marketer+at+Publicis+Global+Delivery+++Kraftshala+Reviews.mp4",
    color: '#02BFF5',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42os43m.webp",
    compImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nem08.webp",
    name: 'Bharat',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Career+in+Digital+Marketing+WITHOUT+English+Kraftshala+Reviews++Kraftshala+Experience.mp4",
    color: '#F28746',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ou729.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ni4rx.webp",
    name: 'Sonia Prasad',
    width: '90px', Mobwidth: '56px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Is+Kraftshala+the+Best+Choice+to+Pursue+Core+Marketing+Kraftshala+Marketing+Launchpad+Reviews.mp4",
    color: '#F1AE13',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ow7al.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42nem08.webp",
    name: 'Abhishek Sharda',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-How+Does+Kraftshala+Help+in+Switching+From+Engineering+to+Marketing_+Kraftshala+Reviews.mp4",
    color: '#23B180',
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42oyiw1.webp",
    compImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42njtdh.webp",
    name: 'Harish Tureha',
    width: '146px'
  },

  
];
