import React from "react";
import "./styles.scss";
import Marquee from 'react-fast-marquee';


const index = () => {
  return (
    <div className="lp-community">
      <div className="lp-community-hero">
        <div className="lp-community-sub-head">
            Community of Digital Marketing Professionals
        </div>
        <div className="l-community-head">
            The Kraftshala Community
        </div>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28kqanp.png" className="lp-community-underline" alt="" />
        <div className="l-community-desc">
        Lifelong relationships to ensure you have the professional network that adds value to your career. Or even some incredible friends.
        </div>
      </div>
      <Marquee  gradient={false}
        speed={67}
        pauseOnHover={false}
        className="miner-community"
        loop={0} >
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42lp58v.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42mhd8r.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42mjtfo.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42mlquc.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42msgps.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42mtt5z.webp" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42mveiv.webp" loading='lazy' className='marqueImg'/>
      </Marquee>
    </div>
  );
};

export default index;
