import React from "react";
import "./styles.scss";

const index = () => {
  const handleBtnClick = () => {
    const element = document.getElementById("certification");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="lp-hero-sect">
      <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42i9yqg.png"
        alt=""
        className="dots-middle-lp"
      />
      <div className="lp-hero-text-sec">
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42k41fh.png"
          alt=""
          className="dots-right-lp"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42izjk2.webp"
          alt=""
          className="lp-mlp-logo"
        />
        <div className="lp-hero-head">
          Best Digital Marketing Course in India with Placements
        </div>
        <div className="lp-hero-desc">
          Learn basic to advanced digital marketing skills and get a
          high-potential job at top brands.
          <br />
          <br className="pc-none" />
          Choose from full-time or part-time courses & graduate with a job and
          not just a certificate!{" "}
        </div>
        <div className="lp-heo-flex">
          <span>
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42ibhot.webp"
              alt=""
              className="icon-lp"
            />
            750 hours of training
          </span>
          <span className="lp-bar">|</span>
          <span>
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42i81qc.webp"
              alt=""
              className="icon-lp caseIcon"
            />
            94% Placement Rate
          </span>
          <span className="lp-bar">|</span>
          <span>
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jqxt8.webp"
              alt=""
              className="icon-lp icon-bg"
            />
            2000+ students placed
          </span>
        </div>
        <div className="lp-heo-flex">
          <span>
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42jxbrg.webp"
              alt=""
              className="icon-lp icon-sm"
            />
            Real Accountability of outcomes
            <br className="pc-none" />
            <br className="pc-none" />
            <span className="flex-normal-txt">
              {" "}
              <span className="mobile-none">-</span> If the job pays &lt; ₹4.5L,
              we refund <b>60%</b> of the fees
            </span>
          </span>
        </div>
        <button className="lp-learn" onClick={handleBtnClick}>
          Learn More
        </button>
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42iqtlz.webp"
        loading="lazy"
        className="lp-hero-img"
      />
    </div>
  );
};

export default index;
